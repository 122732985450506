// libraries
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Modal } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

// services
import api from "services/api";

// hooks
import useTemplateContext from "hooks/use-template-context";

// common
import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import AppSubscribeCardDto from "dto/components/app-subscribe-card-dto";
import { ImperativeHandleAppSubscribeCardDto } from "dto/components/app-subscribe-card-dto";
import { ImperativeHandleAppPopupCardDto } from "dto/components/app-popup-card-dto";

// components
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppPopupCard from "components/app-popup-card";

// assets
import WhiteCloseIcon from "assets/images/white-close-icon.svg";
import emailReceivedIcon from "assets/images/email-received.png";
import resolutionImg from "assets/images/pages/page-home/thumb-it-resolution.jpg";

const AppSubscribeCard = (props: AppSubscribeCardDto, ref: React.ForwardedRef<ImperativeHandleAppSubscribeCardDto>) => {
	const modalRef = useRef<ImperativeHandleAppPopupCardDto>(null);
	const [isVisible, setIsVisible] = useState(false);
	const { getFilteredResources } = useTemplateContext();

	const newsletterPage = useMemo(() => getFilteredResources("page.newsletter"), [getFilteredResources]);
	const getPageData = useCallback((key: string, defaultValue: string = "") => newsletterPage?.find((value) => value.key === key)?.value?.toString() ?? defaultValue, [newsletterPage]);
	const newsletterHeader = useMemo(() => getPageData("page.newsletter.header"), [getPageData]);
	const newsletterDesc = useMemo(() => getPageData("page.newsletter.description"), [getPageData]);
	const newsletterLabel = useMemo(() => getPageData("page.newsletter.cta.label", "Email"), [getPageData]);

	const modalClassNames = useMemo(() => {
		return classNames({
			"subscribe-card": true,
			"subscribe-card--visible": isVisible,
		});
	}, [isVisible]);

	const subscribeImage = resolutionImg;
	const modalTitle = "Thank you!";
	const modalSubTitle = "You have been subscribed to our newsletter";

	//prettier-ignore
	const formik = useFormik({
		initialValues: { email: "" },
		validationSchema: yup.object({
			email: yup.string().email("Invalid email address").required("Required"),
		}),
		onSubmit: async (values) => {
			const formData = new FormData();

			formData.append("newsletterEmail", values.email);

			try {
				await api.post.newsLetter.form(formData);

				modalRef.current?.onHandleShow();
				onHandleDismiss();
			} catch (unknown: unknown) {
				const error = unknown as Error | ErrorResponseDto;

				serveRequestErrors(error);
			}
		},
	});

	const onHandleDismiss = () => {
		setIsVisible(false);
	};

	const onHandleShow = () => {
		formik.resetForm();
		setIsVisible(true);
	};

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	return (
		<>
			<Modal classes={{ root: "app-subscribe-card" }} open={isVisible} aria-labelledby="app-subscribe-card" aria-describedby="app-subscribe-card" onClose={onHandleDismiss}>
				<div className={modalClassNames}>
					<div className="subscribe-card__header">
						<img className="subscribe-card__close-btn" src={WhiteCloseIcon} alt="icon" onClick={onHandleDismiss} />
					</div>

					<div className="subscribe-card__container">
						<div className="subscribe-card__img-wrapper">
							<img className="subscribe-card__img" src={subscribeImage} alt="icon" />
						</div>

						<div className="subscribe-card__wrapper">
							<div className="subscribe-card__title">{newsletterHeader}</div>

							<div className="subscribe-card__description">{newsletterDesc}</div>

							<form className="subscribe-card__form-wrapper" onSubmit={formik.handleSubmit} noValidate>
								{/*prettier-ignore*/}
								<AppInput required type="email" name="email" label="Email" placeholder="Enter your email here..." onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} />

								<div className="terms-section">
									<AppButton type="submit" label={newsletterLabel} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</Modal>

			<AppPopupCard ref={modalRef} image={emailReceivedIcon} title={modalTitle} subTitle={modalSubTitle} />
		</>
	);
};

export default memo(forwardRef(AppSubscribeCard));
