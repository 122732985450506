import { memo, useMemo } from "react";
import AnimatedNumbers from "react-animated-numbers";

import { AppCompanyCardDto } from "dto/components/app-company-card-dto";

const AppCompanyCard = ({ description, number, workforce, description1 }: AppCompanyCardDto) => {
	const parsedNumber = useMemo(() => (typeof number === "string" ? parseInt(number.replace(/[^0-9]/g, ""), 10) : number), [number]);
	const hasPlusSuffix = typeof number === "string" && number.includes("+");
	const splitDescription1 = useMemo(() => description1?.split(".").filter(Boolean) || [], [description1]);
	const numberSize = window.innerWidth > 489 ? 72 : 60;
	return (
		<div className="app-company-card">
			<div className="company-card">
				<p className="company-card__description">{description}</p>

				<div className="company-card__section">
					<div className="company-card__number">
						<AnimatedNumbers
							includeComma
							animateToNumber={parsedNumber}
							fontStyle={{ fontSize: numberSize, fontWeight: "bold", color: "#FFFFFF" }}
							configs={[
								{ mass: 1, tension: 220, friction: 100 },
								{ mass: 1, tension: 180, friction: 130 },
								{ mass: 1, tension: 280, friction: 90 },
								{ mass: 1, tension: 180, friction: 135 },
								{ mass: 1, tension: 260, friction: 100 },
								{ mass: 1, tension: 210, friction: 180 },
							]}
						/>
						{hasPlusSuffix && <span className="suffix">+</span>}
					</div>

					<p className="company-card__title">{workforce}</p>

					{splitDescription1.map((line, index) => (
						<span key={index} className="company-card__description">
							{line.trim()}.
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default memo(AppCompanyCard);
