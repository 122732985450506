import classNames from "common/class-names";

import { AppHeaderCardDto } from "dto/components/app-header-card-dto";

const HeaderCard = ({ backgroundImage, title, headerType = "main", isLargeTitle = false, removeBackgroundColor = false }: AppHeaderCardDto) => {
	const imageClassName = classNames({
		"app-header-card__image": true,
		"app-header-card__image--post": headerType === "post",
		"app-header-card__image--top": headerType === "top",
	});

	const backgroundGradient =
		headerType === "post"
			? `linear-gradient(
					180deg,
					rgba(20, 20, 20, 0),
					rgba(20, 20, 20, 0.88) 99.99%,
					rgba(20, 20, 20, 1)
				)`
			: `linear-gradient(
					180deg,
					rgba(20, 20, 20, 0),
					rgba(20, 20, 20, 0.5) 99%,
					rgba(20, 20, 20, 0.5)
				)`;

	const titleClassNames = classNames({
		"app-header-card__title": true,
		"app-header-card__title--large": isLargeTitle,
	});

	const headerCardClassNames = classNames({
		"app-header-card": true,
		"app-header-card--no-bg": removeBackgroundColor,
	});

	return (
		<div className={headerCardClassNames}>
			<div
				className={imageClassName}
				style={{
					backgroundImage: `${backgroundGradient}, url(${backgroundImage})`,
				}}
			>
				<p className={titleClassNames}>{title}</p>
			</div>
		</div>
	);
};

export default HeaderCard;
