import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";

import useWindowSize from "../hooks/use-window-size";

import classNames from "common/class-names";

import AppSelectInputDto, { AppSelectInputPaginationDto, ImperativeHandleAppSelectDto, SelectOption } from "dto/components/app-select-input-dto";

import lockIcon from "assets/images/lock-icon.svg";
import chevronIcon from "assets/images/components/app-select-input/chevron-icon.svg";

const AppSelectInput = (p: AppSelectInputDto, ref: React.ForwardedRef<ImperativeHandleAppSelectDto>) => {
	const sizes = useWindowSize();

	const { touched, error, loadOptions, value, options, searchable = true, customWidth, ...props } = p;

	const selectOptionFilterInputRef = useRef<HTMLInputElement>(null);

	const inputRef = useRef<HTMLInputElement>(null);

	const inputValueRef = useRef<HTMLInputElement>(null);

	const optionsRef = useRef<HTMLUListElement>(null);

	const paginationRef = useRef<AppSelectInputPaginationDto>({ keyword: "", totalPages: 0, page: 0, size: 10 });

	const loadedOptionsRef = useRef(false);

	const [visible, setVisible] = useState(false);

	const [fetching, setFetching] = useState<boolean>(false);

	const [localOptions, setLocalOptions] = useState<SelectOption[]>([]);

	const [defaultPaginationOption, setDefaultPaginationOption] = useState<SelectOption>();

	const [requestError, setRequestError] = useState<string>("");

	const [search, setSearch] = useState<string>("");

	const [adjusted, setAdjusted] = useState<boolean>(false);

	const isInvalid = useMemo(() => Boolean(error) && Boolean(touched), [error, touched]);

	const placeholder = useMemo(() => props.placeholder ?? "Please Select... ", [props.placeholder]);

	const loadOptionsFunction = useMemo(() => loadOptions, [loadOptions]);

	const inputClassNames = useMemo(() => classNames({ "select-input": true, "select-input--error": isInvalid, "select-input--disabled": Boolean(props.disabled) }), [isInvalid, props.disabled]);

	const selectOptionsClassName = useMemo(() => classNames({ "select-options__options": true, "select-options__options--searchable": searchable }), [searchable]);

	const bodyClassNames = useMemo(
		() => classNames({ "select-input__body": true, "select-input__body--error": isInvalid, "select-input--disabled": Boolean(props.disabled), "select-input__body--custom-width": !!customWidth }),
		[customWidth, isInvalid, props.disabled]
	);

	const selectOptions = useMemo(() => {
		let nextOptions = [] as SelectOption[];

		if (options) nextOptions = options;

		if (localOptions?.length) nextOptions = localOptions;

		if (search) nextOptions = nextOptions.filter((o) => o.label.toLowerCase().includes(search.toLowerCase()));

		return nextOptions;
	}, [options, localOptions, search]);

	const currentvalues = useMemo(() => {
		if (fetching) return "Loading...";

		const selected = selectOptions.find((o) => o.value === value)?.label ?? "";

		if (!selected && defaultPaginationOption) return defaultPaginationOption.label;

		return selected;
	}, [value, fetching, selectOptions, defaultPaginationOption]);

	//prettier-ignore
	const onHandleLoadOptions = useCallback(async () => {
		if (!loadOptionsFunction) return;
		
		setFetching(true);

		setRequestError("");

		try {
			const response = await loadOptionsFunction(paginationRef.current);

			if (paginationRef.current?.page >= 1) {
				setLocalOptions((prev) => [...prev, ...response.data.content]);
			} else {
				setLocalOptions(response.data.content);
			}

			if("totalPages" in response.data && "number" in response.data) {
				paginationRef.current = { ...paginationRef.current, totalPages: response.data.totalPages!, page: response.data.number! + 1 };
			}
		} catch (unknown: unknown) {
			const error = unknown as string;

			setRequestError(error);
		} finally {
			setFetching(false);
		}
	}, [ loadOptionsFunction]);

	//prettier-ignore
	const onHandleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		paginationRef.current.keyword = value;

		if(props.pagination) {
			onHandleLoadOptions();
		} else {
			setSearch(value);
		}

        setAdjusted(true);
	}, [props.pagination, onHandleLoadOptions]);

	const debounceSearch = debounce(onHandleSearch, 1000);

	//prettier-ignore
	const onHandleScroll = useCallback((event: React.UIEvent<HTMLUListElement>) => {
		if (!props.pagination) return;

		const maxHeight = 300;
		const page = paginationRef.current.page;
		const total = paginationRef.current.totalPages;
		const scrollHeight = event.currentTarget.scrollHeight;
		const scrollTop = event.currentTarget.scrollTop + maxHeight;

		if (scrollTop === scrollHeight && total > page && !fetching) {
			onHandleLoadOptions();
		}
	}, [props.pagination, fetching,onHandleLoadOptions]);

	//prettier-ignore
	const onHandleToggleOptions = useCallback((event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
		const target = event.target as HTMLButtonElement | HTMLDivElement;

		/* PREVENT INPUT FOCUS AND OPTION CLICK */
		if(target.id !== "select-options-overlay" && visible) return;

		setVisible((prev) => !prev);

		if(visible) setSearch("");

		if (!visible && selectOptions.length === 0) {
			/* DROPDOWN SHOWN & OPTIONS IS EMPTY */
			onHandleLoadOptions();
		}
	}, [visible, selectOptions, onHandleLoadOptions]);

	//prettier-ignore
	const onHandleSelectItem = useCallback((event: React.BaseSyntheticEvent) => {
		const value = event.target.dataset.value;

		if (inputValueRef.current) {
			const event = new Event("input", { bubbles: true });

			inputValueRef.current.value = value;

			inputValueRef.current.dispatchEvent(event);
		}

		setVisible(false);

		setSearch("");
	}, []);

	const ChevronIcon = useCallback((obj: { disabled: boolean; visible: boolean }) => {
		if (obj.disabled) return null;

		const chevronClassName = classNames({ "select-input__chevron-icon": true, "select-input__chevron-icon--show": obj.visible });

		return (
			<div className={chevronClassName}>
				<img src={chevronIcon} alt={obj.visible ? "show options" : "hide options"} />
			</div>
		);
	}, []);

	const Icon = useCallback((obj: { disabled: boolean }) => {
		if (!obj.disabled) return null;

		return (
			<div className="select-input__icon">
				<img src={lockIcon} alt="input disabled" />
			</div>
		);
	}, []);

	useEffect(() => {
		let currentRef: HTMLInputElement | null = null;

		if (inputValueRef.current) {
			inputValueRef.current.addEventListener("input", (event) => {
				props.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
			});

			currentRef = inputValueRef.current;
		}

		return () => {
			currentRef?.removeEventListener("input", (event) => {
				props.onChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
			});
		};
	}, [props]);

	useEffect(() => {
		if ((visible && sizes) || (visible && selectOptions.length) || (visible && adjusted)) {
			const parentNode = inputRef.current?.parentNode as HTMLButtonElement;

			if (parentNode) {
				const rect = parentNode.getBoundingClientRect();
				optionsRef.current!.style.left = rect.left + "px";
				optionsRef.current!.style.maxWidth = parentNode.clientWidth + "px";
				const optionsHeight = optionsRef.current!.clientHeight;

				if (optionsHeight >= sizes[1]) {
					optionsRef.current!.style.bottom = "15px";
				} else {
					optionsRef.current!.style.bottom = "unset";
				}

				optionsRef.current!.style.zIndex = "1000";
			}

			setAdjusted(false);
		}
	}, [sizes, visible, selectOptions, adjusted]);

	useEffect(() => {
		if (value && !selectOptions?.length && !loadedOptionsRef.current) {
			/* PROPS.VALUE NOT EMPTY */
			/* LOAD OPTIONS FUNCTION */
			/* OPTIONS IS EMPTY */
			/* API OPTIONS IS EMPTY, PREVENT INFINITY CALL */
			loadedOptionsRef.current = true;

			onHandleLoadOptions();
		}
	}, [onHandleLoadOptions, value, selectOptions]);

	useEffect(() => {
		const onHandleGetDefaultPagination = async () => {
			try {
				const response = await loadOptionsFunction!({ keyword: value as string, totalPages: 0, page: 0, size: 1 });

				setDefaultPaginationOption({ label: response.data.content[0].label, value: response.data.content[0].value });
			} catch (unknown: unknown) {
				const error = unknown as string;

				setRequestError(error);
			}
		};

		if (props.pagination && value && !selectOptions?.length && loadOptionsFunction) onHandleGetDefaultPagination();
	}, [props.pagination, value, selectOptions, loadOptionsFunction]);

	useEffect(() => {
		const handleScroll = () => {
			if (visible) {
				const rect = inputRef.current?.getBoundingClientRect();
				if (rect && (rect.top < 0 || rect.bottom > window.innerHeight)) {
					setVisible(false);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [visible]);

	useImperativeHandle(ref, () => ({
		getOptionsData: () => selectOptions,
	}));

	return (
		<div className="app-select-input">
			<div className={inputClassNames}>
				<label className="select-input__label" htmlFor={props.name}>
					{props.label}

					{props.required && <span className="select-input__required"> *</span>}
				</label>

				<button type="button" className={bodyClassNames} disabled={props.disabled} onClick={onHandleToggleOptions}>
					<input readOnly id={props.name} className="select-input__input" ref={inputRef} required={false} value={currentvalues} type="text" placeholder={placeholder} autoComplete="off" />

					<ChevronIcon disabled={Boolean(props.disabled)} visible={visible} />

					<Icon disabled={Boolean(props.disabled)} />
				</button>

				{isInvalid && (
					<div className="select-input__footer">
						<p className="select-input__error">{error}</p>
					</div>
				)}

				<input readOnly hidden id={props.name} name={props.name} type="text" ref={inputValueRef} />
			</div>

			{visible && <div id="select-options-overlay" className="select-options" onClick={onHandleToggleOptions}></div>}

			{visible && (
				<ul className={selectOptionsClassName} ref={optionsRef} onScroll={onHandleScroll}>
					{searchable && <input type="text" ref={selectOptionFilterInputRef} className="select-options__filter-input" placeholder="Search..." onChange={debounceSearch} />}

					{selectOptions.map((o: SelectOption, i: number) => {
						const optionClassName = classNames({ "select-options__option": true, "select-options__option--active": o.value === value });

						return (
							<li className={optionClassName} key={i}>
								<button type="button" className="select-options__button" disabled={o.disabled} onClick={onHandleSelectItem} data-value={o.value}>
									{o.label}
								</button>
							</li>
						);
					})}

					{!selectOptions.length && !fetching && (
						<li className="select-options__option-text">
							<p className="select-options__empty">No Options</p>
						</li>
					)}

					{fetching && (
						<li className="select-options__option-text">
							<p className="select-options__fetching">Fetching...</p>
						</li>
					)}

					{requestError && (
						<li className="select-options__option-text">
							<p className="select-options__error">{requestError}</p>
						</li>
					)}
				</ul>
			)}
		</div>
	);
};

export default memo(forwardRef(AppSelectInput));
