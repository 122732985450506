import axios from "axios";

import AppButton from "components/app-button";
import AppBrochureCardDto from "dto/components/app-brochure-card-dto";

const AppBrochureCard = ({ title, image, link }: AppBrochureCardDto) => {
	const handleClick = () => downloadPDF(link, title);

	const downloadPDF = async (url: string, filename: string) => {
		try {
			const response = await axios.get(url, { responseType: "blob" });

			const fileURL = URL.createObjectURL(response.data);
			const downloadLink = document.createElement("a");
			downloadLink.href = fileURL;
			downloadLink.download = `${filename}.pdf`;

			downloadLink.click();

			URL.revokeObjectURL(fileURL);
		} catch (error) {
			console.error("Download failed:", error);
		}
	};

	const handlePreview = () => {
		window.open(`https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodeURIComponent(link)}`, "_blank");
	};

	return (
		<div className="app-brochure-card">
			<div className="brochure-card">
				<div className="brochure-card__container" onClick={handlePreview}>
					<div className="brochure-card__image" style={{ backgroundImage: `url(${image})` }} />
				</div>

				{/*prettier-ignore*/}
				<p className="brochure-card__title" title={title}>{title}</p>

				<AppButton className="brochure-card__btn" label="Download E-Brochure" onClick={handleClick} tertiary />
			</div>
		</div>
	);
};

export default AppBrochureCard;
