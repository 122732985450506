const END_POINT_PATH = {
	SOLUTIONS: {
		SOLUTIONS: "solution/search",
		SOLUTION: "/solution/categorySearch",
		SOLUTION_TITLE: "solutionGroup/list",
		BROCHURE: "/brochure/searchMain/",
	},
	CLIENTS: {
		CLIENTS: "/client/search",
		CLIENT: "/client/",
	},
	ARTICLE: {
		ARTICLE_DETAILS: "/article/",
		SIMILAR_ARTICLE: "/article/categorySearch/",
		SIMILAR_CATEGORY: (id: string, category: string) => `article/search/${id}/similar/category/${category}`,
		OUR_ARTICLES: "/article/search/",
		CATEGORY_TITLE: "/articleCategory/search",
		ALL_ARTICLES: "/article/searchArticle",
		LATEST_ARTICLES: "/article/searchArticle",
	},
	FAQ: {
		FAQ: "/faq/list",
	},
	SERVICES: {
		SERVICES: "/solutionCategories/categorySearch/",
		SERVICES_CARD: "/solution/list/main/status/",
	},
	PARTNER: {
		PARTNER: "/partner/list",
	},
	CAREER: {
		CAREER: "/career/search/",
		// FORM: "/career/career-form",
		FORM: "/career/submit/",
		HIRING: "/career/hiring",
		POSITION: "/career/list",
		HIRING_SEARCH: "/career/hiringSearch/hiringStatus",
	},
	BRANCH: {
		BRANCH: "/branch/retrieve",
	},
	DROPDOWN: {
		RETRIEVE_CATEGORY: "/dropdown/retrieve/",
	},
	NEWSLETTER: {
		FORM: "/newsletter/email",
	},
	RESOURCE: {
		TEMPLATE: "/resource/active/template",
		RESOURCE: "/resource/find/customer/template/",
	},
	STORY: {
		STORY: "/journey/list",
	},
	ANNOUNCEMENT: {
		DISPLAY: "/announcement/display",
	},
	CONTACT_US: {
		FORM: "/enquiry/save/",
	},
};

export default END_POINT_PATH;
