import classNames from "common/class-names";

import { AppArticleDetailsDto } from "dto/components/app-article-info/app-article-dto";

import AppArticleResourcePlayer from "components/app-article-resource-player";

const AppArticleDetails = ({ content }: AppArticleDetailsDto) => {
	if (!content || content?.length === 0) {
		return null;
	}

	return (
		<div className="app-article-details">
			<div className="article-details">
				{content.map((item, index) => {
					if (!item.contentType) return null;

					if (item.contentType?.toLowerCase() === "image" && item.medias?.length > 0) return <AppArticleResourcePlayer key={index} resources={item.medias} />;

					const itemClassNames = classNames({
						"article-details__content": true,
						"article-details__content--first": index === 0 && content?.length > 0,
					});

					const replaceText = { __html: item.subContent };

					return (
						<div key={index} className={itemClassNames}>
							{item.subTitle && <p className="article-details__title">{item.subTitle}</p>}

							<div className="article-details__description">
								<p dangerouslySetInnerHTML={replaceText}></p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AppArticleDetails;
