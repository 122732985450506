const formatDate = (dateString: any) => {
	const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };

	const date = new Date(dateString);

	if (isNaN(date.getTime())) {
		return;
	}

	return date.toLocaleDateString("en-US", options);
};

export default formatDate;
