import { createContext, FC, useCallback, useEffect, useMemo, useState } from "react";

import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

import ErrorResponseDto from "dto/services/error-response-dto";
import { ResourceContentDto } from "dto/services/resource-data-dto";
import TemplateContextDto, { Props } from "dto/contexts/template-context-dto";

import AppScreenLoader from "components/app-screen-loader";

const TemplateContext = createContext<TemplateContextDto>({} as TemplateContextDto);

const TemplateProvider: FC<Props> = ({ children }) => {
	const [activeTemplateData, setActiveTemplateData] = useState<string>("")
	const [resourceData, setResourceData] = useState<ResourceContentDto[]>([]);

	const onHandleGetResourceData = useCallback(async () => {
		try {
			const templateResponse = await api.get.resource.template();
			const templateData = templateResponse.data.data;
			
			if (Boolean(templateData)) {
				if (templateData !== "template_1") document.documentElement.setAttribute("data-theme", "theme2");
				
				const response = await api.get.resource.resource(templateData);
				setResourceData(response.data.data.resourceConfig.contents);
				setActiveTemplateData(templateData)
			}
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const getFilteredResources = useMemo(() => {
		return (page: string) => {
			return resourceData.find((content) => content.page === page)?.values;
		};
	}, [resourceData]);

	useEffect(() => {
		onHandleGetResourceData();
	}, [onHandleGetResourceData]);

	return <TemplateContext.Provider value={{ getFilteredResources, activeTemplateData }}>{resourceData.length > 0 ? children : <AppScreenLoader />}</TemplateContext.Provider>;
};

export { TemplateProvider, TemplateContext };
