import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {
	children: ReactElement;
};

const ScrollToTop = ({ children }: Props) => {
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}, 10);
	}, [location.pathname]);

	return children;
};

export default ScrollToTop;
