//library
import { useCallback, useMemo, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//services
import api from "services/api";
import pathnames from "routes/pathnames";

// hooks
import useWindowSize from "hooks/use-window-size";
import useTemplateContext from "hooks/use-template-context";

//common
import serveRequestErrors from "common/serve-request-errors";

//dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ImperativeHandleCategoryTabDto } from "dto/components/app-category-tab-dto";
import { SolutionCardDto, SolutionBrochureDto } from "dto/pages/page-all-products-dto";
import { ImperativeHandleCategoryDropdownDto } from "dto/components/app-category-dropdown";

//components
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import HeaderCard from "components/app-header-card";
import AppPagination from "components/app-pagination";
import AppServicesCard from "components/app-services-card";
import AppBrochureCard from "components/app-brochure-card";
import AppContentTab from "components/app-content-tab";
import AppCategoryTab from "components/app-category-tab";
import AppCategoryDropdown from "components/app-category-dropdown";

const PageAllProducts = () => {
	const sizes = useWindowSize();
	const navigate = useNavigate();
	const queryParams = useMemo(() => ({ search: "", page: 0, size: 100 }), []);
	const isMobile = useMemo(() => (sizes?.[0] <= 489 ? true : false), [sizes]);
	const tabRef = useRef<ImperativeHandleCategoryTabDto>(null);
	const dropdownRef = useRef<ImperativeHandleCategoryDropdownDto>(null);

	const [currentPage, setCurrentPage] = useState(0);
	const [selectedContentTab, setSelectedContentTab] = useState(0);
	const [currentBrochurePage, setCurrentBrochurePage] = useState(0);
	const [autoService, setAutoService] = useState(true);
	const [autoBrochure, setAutoBrochure] = useState(true);
	const [servicesCard, setServicesCard] = useState<SolutionCardDto[]>([]);
	const [formattedBrochureHeader, setFormattedBrochureHeader] = useState("");
	const [brochureData, setBrochureData] = useState<SolutionBrochureDto[]>([]);
	const [contentTitle, setContentTitle] = useState<SolutionCardDto[]>([]);
	const [categoriesTitleData, setCategoriesTitleData] = useState<SolutionCardDto[]>([]);
	const { getFilteredResources } = useTemplateContext();

	const costumeWidth = sizes?.[0];
	const pageConstant = useMemo(() => (costumeWidth <= 500 ? 2 : costumeWidth <= 350 ? 1 : 4), [costumeWidth]);
	const totalPageNumber = useMemo(() => (Array.isArray(servicesCard) ? Math.ceil(servicesCard.length / 9) : 0), [servicesCard]);
	const paginatedServices = useMemo(() => {
		const itemsPerPage = isMobile ? 6 : 9;
		return servicesCard.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
	}, [currentPage, servicesCard, isMobile]);
	const totalBrochurePageNumber = useMemo(() => Math.ceil(brochureData.length / pageConstant), [brochureData.length, pageConstant]);
	const paginatedBrochure = useMemo(() => brochureData.slice(currentBrochurePage * pageConstant, (currentBrochurePage + 1) * pageConstant), [brochureData, currentBrochurePage, pageConstant]);

	const servicePage = useMemo(() => getFilteredResources("page.service"), [getFilteredResources]);
	const getPageData = useCallback((key: any) => servicePage?.find((value) => value.key === key)?.value?.toString() ?? "", [servicePage]);
	const serviceCoverHeader = useMemo(() => getPageData("page.service.cover.header"), [getPageData]);
	const serviceCover = useMemo(() => getPageData("page.service.cover"), [getPageData]);
	const navigateButton = useMemo(() => getPageData("page.service.cta.navigation"), [getPageData]);

	const onHandleNext = useCallback(() => {
		setAutoService(false);
		if (currentPage !== totalPageNumber - 1) {
			setCurrentPage((prev) => ++prev);
		}
	}, [currentPage, totalPageNumber]);

	const onHandleBack = useCallback(() => {
		setAutoService(false);
		if (currentPage !== 0) {
			setCurrentPage((prev) => --prev);
		}
	}, [currentPage]);

	const onHandleBrochureNext = useCallback(() => {
		setAutoBrochure(false);
		if (currentBrochurePage !== totalBrochurePageNumber - 1) {
			setCurrentBrochurePage((prev) => ++prev);
		}
	}, [currentBrochurePage, totalBrochurePageNumber]);

	const onHandleBrochureBack = useCallback(() => {
		setAutoBrochure(false);
		if (currentBrochurePage !== 0) {
			setCurrentBrochurePage((prev) => --prev);
		}
	}, [currentBrochurePage]);

	const fetchData = useCallback(async (apiCall: any, setter: any) => {
		try {
			const response = await apiCall();
			setter(response.data?.data?.list?.content ?? []);
			return response;
		} catch (error) {
			const err = error as Error | ErrorResponseDto;
			serveRequestErrors(err);
		}
	}, []);

	//prettier-ignore
	const onHandleGetServices = useCallback(async (id: string) => {
		const params = queryParams;

		await fetchData(() => api.get.services.servicesCard(id, params), setServicesCard);
		setAutoService(true);
	}, [queryParams, fetchData]);

	//prettier-ignore
	const onHandleGetBrochureData = useCallback(async (id: string) => {
		const params = queryParams;

		await fetchData(() => api.get.solutions.brochure(id, params), setBrochureData);
	}, [queryParams, fetchData]);

	//prettier-ignore
	const onHandleGetCategoriesTitle = useCallback(async (id: string) => {
		const params = queryParams;

		const response = await fetchData(() => api.get.services.services(id, params), setCategoriesTitleData);

		const dataContent: SolutionCardDto[] = response.data.data.list.content;

		const activeDataContent = dataContent.filter((item) => item.status === "active");

		const mapDataContent = activeDataContent.map((m, i) => ({ ...m, indexKey: i }));

		setCategoriesTitleData(mapDataContent);

		onHandleGetServices(mapDataContent[0].id);

		onHandleGetBrochureData(mapDataContent[0].id);
	}, [fetchData, onHandleGetBrochureData, onHandleGetServices, queryParams]);

	const onHandleGetContentTitle = useCallback(async () => {
		const response = await fetchData(() => api.get.solutions.solutionTitle(), setContentTitle);

		const dataContent: SolutionCardDto[] = response.data.data.list;

		const activeDataContent = dataContent.filter((item) => item.status === "active");

		const mapDataContent = activeDataContent.map((m, i) => ({ ...m, indexKey: i }));

		setContentTitle(mapDataContent);

		onHandleGetCategoriesTitle(mapDataContent[0].id);
	}, [fetchData, onHandleGetCategoriesTitle]);

	//prettier-ignore
	const onHandleSelectContent = useCallback((tab: any) => {
		const categoryId = tab.id;

		setSelectedContentTab(tab.indexKey);

		setCurrentPage(0);
		
		onHandleGetCategoriesTitle(categoryId);
	}, [onHandleGetCategoriesTitle]);

	//prettier-ignore
	const onHandleSelect = useCallback((tab: any) => {
		const categoryId = tab.id;
		
		const title = `${tab.title} E-Brochure`.replace(/-/g, "‑");

		setFormattedBrochureHeader(title);

		setCurrentPage(0);

		setCurrentBrochurePage(0);

		onHandleGetServices(categoryId);

		onHandleGetBrochureData(categoryId)
	}, [onHandleGetBrochureData, onHandleGetServices]);

	useEffect(() => {
		onHandleGetContentTitle();
	}, [onHandleGetContentTitle]);

	return (
		<div className="page-all-products">
			<div className="products">
				<AppNavbar />

				<HeaderCard backgroundImage={serviceCover} title={serviceCoverHeader} />

				<div className="tab-section">
					{contentTitle.length > 0 &&
						(isMobile ? <AppCategoryDropdown ref={tabRef} categories={contentTitle} onSelect={onHandleSelectContent} /> : <AppContentTab ref={tabRef} tabs={contentTitle} onSelect={onHandleSelectContent} />)}
				</div>

				<div className="tabs-section">
					{categoriesTitleData.length > 0 &&
						(isMobile ? (
							<AppCategoryDropdown ref={dropdownRef} categories={categoriesTitleData} onSelect={onHandleSelect} />
						) : (
							<AppCategoryTab ref={tabRef} tabs={categoriesTitleData} onSelect={onHandleSelect} selectedContentTab={selectedContentTab} />
						))}
				</div>

				{categoriesTitleData.length > 0 && (
					<div className="services-section">
						<p className="services-section__title">{isMobile ? dropdownRef.current?.selectedDetails[0]?.title : tabRef.current?.selectedDetails[0]?.title}</p>

						<div className="services-section__after">
							<div className="services-section__header">
								<p className="services-section__description">
									{isMobile
										? dropdownRef.current?.selectedDetails[0]?.fullDescription?.replace(/-/g, "‑") || dropdownRef.current?.selectedDetails[0]?.description?.replace(/-/g, "‑")
										: tabRef.current?.selectedDetails[0]?.fullDescription?.replace(/-/g, "‑") || tabRef.current?.selectedDetails[0]?.description?.replace(/-/g, "‑")}
								</p>

								{!isMobile && totalPageNumber > 1 && (
									<AppPagination
										totalPageNumber={totalPageNumber}
										currentPage={currentPage}
										isLoop={autoService}
										onAuto={(page: number) => setCurrentPage(page)}
										onHandleNext={() => onHandleNext()}
										onHandleBack={() => onHandleBack()}
									/>
								)}
							</div>

							{paginatedServices.length > 0 && (
								<div className="services-section__wrapper">
									{paginatedServices.map((o) => (
										<AppServicesCard title={o.title} image={o.logo} key={o.id} />
									))}
								</div>
							)}

							{isMobile && totalPageNumber > 1 && (
								<div className="services-section__pagination">
									<AppPagination
										totalPageNumber={totalPageNumber}
										currentPage={currentPage}
										isLoop={autoService}
										onAuto={(page: number) => setCurrentPage(page)}
										onHandleNext={() => onHandleNext()}
										onHandleBack={() => onHandleBack()}
									/>
								</div>
							)}

							<div className="services-section__button-container">
								<AppButton label={navigateButton} onClick={() => navigate(pathnames.ContactUs)} />
							</div>
						</div>
					</div>
				)}

				<div className="services-and-solutions-section">
					{paginatedBrochure.length > 0 && (
						<>
							<div className="service-brochure-section">
								<p className="service-brochure-section__service-title">{formattedBrochureHeader}</p>

								{!isMobile && totalBrochurePageNumber > 1 && (
									<AppPagination
										totalPageNumber={totalBrochurePageNumber}
										currentPage={currentBrochurePage}
										isLoop={autoBrochure}
										onAuto={(page: number) => setCurrentBrochurePage(page)}
										onHandleNext={() => onHandleBrochureNext()}
										onHandleBack={() => onHandleBrochureBack()}
									/>
								)}
							</div>

							<div className="service-brochure-section__wrapper">
								{paginatedBrochure.map((o) => (
									<div className="service-brochure-section__box" key={o.id}>
										<AppBrochureCard title={o.title} image={o.cover} link={o.document} key={o.id} />
									</div>
								))}
							</div>

							{isMobile && totalBrochurePageNumber > 1 && (
								<div className="service-brochure-section__pagination">
									<AppPagination
										totalPageNumber={totalBrochurePageNumber}
										currentPage={currentBrochurePage}
										isLoop={autoBrochure}
										onAuto={(page: number) => setCurrentBrochurePage(page)}
										onHandleNext={() => onHandleBrochureNext()}
										onHandleBack={() => onHandleBrochureBack()}
									/>
								</div>
							)}
						</>
					)}
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

export default PageAllProducts;
