import { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";

import { Tab, Tabs } from "@mui/material";

import { CategoryTabsProps, CategoryTab, ImperativeHandleCategoryTabDto } from "dto/components/app-category-tab-dto";

const AppCategoryTab = ({ tabs, onSelect, selectedContentTab }: CategoryTabsProps, ref: React.ForwardedRef<ImperativeHandleCategoryTabDto>) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const isScrollable = tabs?.length * 400 > document.documentElement.clientWidth * 84 ? "scrollable" : "fullWidth";

	const selectedIndex = tabs.findIndex((tab) => tab.indexKey === selectedTab);

	const onHandleTabSelect = (tab: CategoryTab) => {
		setSelectedTab(tab.indexKey || 0);
		if (onSelect) onSelect(tab);
	};

	useImperativeHandle(ref, () => ({
		selectedDetails: tabs?.filter((tab) => tab.indexKey === selectedTab) || [],
	}));

	useEffect(() => {
		setSelectedTab(0);
		if (onSelect && tabs.length > 0) {
			onSelect(tabs[0]);
		}
	}, [tabs, onSelect]);

	return (
		<Tabs
			value={selectedIndex}
			variant={isScrollable}
			scrollButtons="auto"
			aria-label="category tabs"
			className="category-tabs"
			allowScrollButtonsMobile
			TabIndicatorProps={{
				style: {
					backgroundColor: selectedContentTab === 1 ? "var(--c-pagination-highlight-2)" : "var(--theme-highlight)",
				},
			}}
			sx={{
				"& .MuiTabs-indicator": {
					backgroundColor: selectedContentTab === 1 ? "var(--c-pagination-highlight-2)" : "var(--theme-highlight)",
				},
				"& .Mui-selected": {
					color: "var(--theme-text-color) !important",
				},
				"& .MuiTouchRipple-root": {
					color: "var(--theme-highlight)",
				},
			}}
		>
			{tabs.map((tab) => (
				<Tab key={tab.indexKey} label={tab.title} onClick={() => onHandleTabSelect(tab)} className="tab" />
			))}
		</Tabs>
	);
};

export default memo(forwardRef(AppCategoryTab));
