import { AxiosResponse } from "axios";

import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

import { SelectOption } from "dto/components/app-select-input-dto";
import ErrorResponseDto from "dto/services/error-response-dto";

const getAllVacanciesListing = async (): Promise<AxiosResponse<{ content: SelectOption[] }>> => {
	let response = null;

	let errorMessage = "";

	try {
		const res = await api.get.career.hiring();

		const content = res.data.data.list.map((o: SelectOption) => ({ label: o.title, value: o.title }));

		content.unshift({ label: "All Vacancies", value: "" });

		response = { ...res, data: { content: content } };
	} catch (error) {
		const err = error as Error | ErrorResponseDto;
		serveRequestErrors(err);
	}

	if (response) return response;

	throw errorMessage;
};

export default getAllVacanciesListing;
