import React, { createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AxiosResponse, InternalAxiosRequestConfig } from "axios";

import services from "services";

import sanitizeError from "common/sanitize-error";

import ErrorResponseDto from "dto/services/error-response-dto";
import { InterceptorContextDto } from "dto/contexts/interceptor-provider-dto";

const sharedStyles = "margin: 5px 0; padding: 8px; border-radius: 20px; color: #FFFFFF;";
const initStyles = `${sharedStyles} background: #673AB7;`;
const ejectStyles = `${sharedStyles} background: #ffb127;`;
const reqDataStyles = `${sharedStyles} background: #F8EA8C;`;
const reqParamsStyles = `${sharedStyles} background: #ff9514;`;
const respSuccessStyles = `${sharedStyles} background: #32ac97;`;
const errorStyles = `${sharedStyles} background: #F79489;`;

// Create context
export const AxiosContext = createContext<InterceptorContextDto>({
	onHandleCancelRequest: () => {},
});

const interceptorDebug = (title: string, styles: string, data?: any) => {
	if (!process.env.NODE_ENV) return;
	console.log(`%c ${title}`, styles);

	if (data) console.log(data);
	console.log("");
};

const InterceptorProvider = ({ children }: { children: React.ReactElement }) => {
	const controller = useRef<Map<string, AbortController>>(new Map()).current;
	const [initialed, setInitialed] = useState(false);
	const requestInterceptor = useRef<number | null>(null);
	const responseInterceptor = useRef<number | null>(null);
	const xApiKey = process.env.REACT_APP_X_API_KEY;

	//prettier-ignore
	const onHandleRequest = useCallback((config: InternalAxiosRequestConfig) => {
		if (xApiKey && !config.headers["X-Api-Key"]) config.headers["X-Api-Key"] = xApiKey;
		

		if (config.method === "get") {
			interceptorDebug(`GET REQUESTING 🚀 ${config.baseURL! + config.url}`, reqParamsStyles, config.params);
		} else if (config.method === "post") {
			interceptorDebug(`POST REQUESTING 🚀 ${config.baseURL! + config.url}`, reqDataStyles, config.data);
		}

		return config;
	}, [xApiKey]);

	//prettier-ignore
	const onHandleRequestError = useCallback((error: ErrorResponseDto): Promise<ErrorResponseDto> => {
		interceptorDebug("REQUESTING ERROR 👎", errorStyles, sanitizeError(error));

		controller.delete(error.config!.url!);

		return Promise.reject(error);
	}, [controller]);

	//prettier-ignore
	const onHandleResponse = useCallback((response: AxiosResponse): AxiosResponse => {
		interceptorDebug(`RESPONSE SUCCESS: 🌟 ${response.config.baseURL! + response.config.url}`, respSuccessStyles, response.data);

		if (response.config.url) controller.delete(response.config.url);

		return response;
	}, [controller]);

	//prettier-ignore
	const onHandleResponseError = useCallback((error: ErrorResponseDto): Promise<ErrorResponseDto> => {
		interceptorDebug(`RESPONSE ERROR 🥲 ${error.config!.baseURL! + error.config!.url}`, errorStyles, sanitizeError(error));

		controller.delete(error.config!.url!);

		return Promise.reject(error);
	}, [controller]);

	const onHandleEjectAxiosInterceptor = useCallback(() => {
		if (requestInterceptor.current !== null) {
			interceptorDebug("Ejected Request Axios Interceptor! 👋", ejectStyles);

			services.interceptors.request.eject(requestInterceptor.current);
		}
		if (responseInterceptor.current !== null) {
			interceptorDebug("Ejected Response Axios Interceptor! 👋", ejectStyles);

			services.interceptors.response.eject(responseInterceptor.current);
		}
	}, []);

	//prettier-ignore
	const onHandleCancelRequest = useCallback((cancelId: string) => {
		const cancelIdController = controller.get(cancelId);

		if (cancelIdController) {
			cancelIdController.abort();
			
			controller.delete(cancelId);
		}
	}, [controller]);

	useEffect(() => {
		interceptorDebug(`Init Axios Interceptor! 🎉`, initStyles);

		requestInterceptor.current = services.interceptors.request.use(onHandleRequest, onHandleRequestError, { synchronous: true });

		responseInterceptor.current = services.interceptors.response.use(onHandleResponse, onHandleResponseError, { synchronous: true });

		setInitialed(true);

		return () => {
			onHandleEjectAxiosInterceptor();
		};
	}, [onHandleRequest, onHandleRequestError, onHandleResponse, onHandleResponseError, onHandleEjectAxiosInterceptor]);

	const contexts = useMemo(() => ({ onHandleCancelRequest }), [onHandleCancelRequest]);

	if (!initialed) return null;

	return <AxiosContext.Provider value={contexts}>{children}</AxiosContext.Provider>;
};

export default memo(InterceptorProvider);
