import React, { memo, forwardRef, useState, useEffect, useCallback, useMemo } from "react";
import { Modal } from "@mui/material";

import formatDate from "common/format-date";

import { AppPopupAdsProps, ImperativeHandleAppPopupAdsDto } from "dto/components/pages/page-home/app-popup-ads-dto";

import AppPagination from "components/app-pagination";

const AppPopupAds = ({ list }: AppPopupAdsProps, ref: React.ForwardedRef<ImperativeHandleAppPopupAdsDto>) => {
	const [isVisible, setIsVisible] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const currentAd = useMemo(() => list[currentStep], [currentStep, list]);

	const onHandleDismiss = () => {
		setIsVisible(false);
		setCurrentStep(0);
	};

	const onHandleShow = () => {
		setIsVisible(true);
	};

	const onHandleNext = useCallback(() => {
		if (list && currentStep < list.length - 1) {
			setCurrentStep((prev) => prev + 1);
		} else {
			setCurrentStep(0);
		}
	}, [currentStep, list]);

	const onHandleBack = useCallback(() => {
		if (currentStep !== 0) {
			setCurrentStep((prev) => --prev);
		} else {
			setCurrentStep(list.length - 1);
		}
	}, [currentStep, list.length]);

	React.useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
	}));

	useEffect(() => {
		if (isVisible && list.length > 1) {
			const timer = setInterval(() => {
				setCurrentStep((prevIndex) => (prevIndex + 1) % list.length);
			}, 20000);

			return () => clearInterval(timer);
		}
	}, [isVisible, list.length]);

	if (!list || list.length === 0) return null;

	return (
		<Modal classes={{ root: "app-popup-ads" }} open={isVisible}>
			<div className="ads">
				<div className="headline">{currentAd.headline}</div>

				<div className="popup-ads">
					<div className="popup-ads__container">
						<div className="popup-ads__img-wrapper">
							<div className="popup-ads__img" style={{ backgroundImage: `url(${currentAd.cover})` }} />
						</div>

						<div className="popup-ads__wrapper">
							<div>
								<div className="popup-ads__description">{formatDate(currentAd.publicationDate)}</div>

								<div className="popup-ads__title">{currentAd.title}</div>

								<div className="popup-ads__description">{currentAd.description}</div>
							</div>

							<div className="popup-ads__steppers">
								<AppPagination className="app-pagination" totalPageNumber={list.length} currentPage={currentStep} isLoop={true} onHandleNext={onHandleNext} onHandleBack={onHandleBack} />
							</div>
						</div>
					</div>
				</div>

				<div className="popup-ads__btn-wrapper">
					<button className="popup-ads__enter-btn" onClick={onHandleDismiss}>
						<span className="enter-symbol">{"< < < < < "}</span>

						<span className="enter-text">Enter Site</span>

						<span className="enter-symbol">{" > > > > >"}</span>
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPopupAds));
