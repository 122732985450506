// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// hooks
import useTemplateContext from "hooks/use-template-context";

import CONSTANTS from "constants/constants";

// common
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ArticleDto, FaqDto, PartnerListDto, ServiceDto, ProsDto, ClientDto } from "dto/pages/page-home-dto";
import AppPopupAdsDto, { ImperativeHandleAppPopupAdsDto } from "dto/components/pages/page-home/app-popup-ads-dto";

// component
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import AppSlideShow from "components/app-slideshow";
import AppArticleCard from "components/app-article-card";
import AppFaqCard from "components/pages/page-home/app-faq-card";
import AppPopupAds from "components/pages/page-home/app-popup-ads";
import AppCompanyCard from "components/pages/page-home/app-company-card";
import AppClientSection from "components/pages/page-home/app-clients-section";
import AppServiceSolutionCard from "components/pages/page-home/app-service-solution-card";

const PageHome = () => {
	const navigate = useNavigate();
	const adsRef = useRef<ImperativeHandleAppPopupAdsDto>(null);
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	const [clientsData, setClientsData] = useState<ClientDto[]>([]);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [ourServices, setOurServices] = useState<ServiceDto[]>([]);
	const [partnerServices, setPartnerServices] = useState<ServiceDto[]>([]);
	const [partnerList, setPartnerList] = useState<PartnerListDto[]>([]);
	const [faqData, setFaqData] = useState<FaqDto[]>([]);
	const [reorderedFaqData, setReorderedFaqData] = useState<FaqDto[]>([]);
	const [articleData, setArticleData] = useState<ArticleDto[]>([]);
	const [headerClassName, setHeaderClassName] = useState("app-navbar__fixed");
	const [adList, setAdList] = useState<AppPopupAdsDto[]>([]);
	const { getFilteredResources } = useTemplateContext();

	const homePage = useMemo(() => getFilteredResources("page.landing"), [getFilteredResources]);
	const getPageData = useCallback((key: string) => homePage?.find((value) => value.key === key)?.value?.toString() ?? "", [homePage]);
	const coverImage = useMemo(() => getPageData("page.landing.cover.video"), [getPageData]);
	const partnerHeader = useMemo(() => getPageData("page.landing.partner.header"), [getPageData]);
	const serviceHeader = useMemo(() => getPageData("page.landing.service.header"), [getPageData]);
	const serviceDescription = useMemo(() => getPageData("page.landing.service.description"), [getPageData]);
	const serviceSubHeader = useMemo(() => getPageData("page.landing.service.subHeader"), [getPageData]);
	const serviceSubHeaderPartner = useMemo(() => getPageData("page.landing.service.subHeaderPartner"), [getPageData]);
	const prosHeader = useMemo(() => getPageData("page.landing.pros.header"), [getPageData]);
	const serviceViewLabel = useMemo(() => getPageData("page.landing.service.view.label"), [getPageData]);
	const articleHeader = useMemo(() => getPageData("page.landing.article.header"), [getPageData]);
	const articleViewLabel = useMemo(() => getPageData("page.landing.article.view.label"), [getPageData]);
	const faqHeader = useMemo(() => getPageData("page.landing.faq.header"), [getPageData]);
	const faqDescription = useMemo(() => getPageData("page.landing.faq.description"), [getPageData]);
	const coverTitle = useMemo(() => getPageData("page.landing.catch.phrase"), [getPageData]);
	const splitCoverTitle = useMemo(() => (Boolean(coverTitle) ? coverTitle.split("<position>") : []), [coverTitle]);
	const coverPosition = useMemo(() => (homePage?.find((value) => value.key === "page.landing.job.position")?.value as string[]) || [], [homePage]);
	const prosData = useMemo(() => (homePage?.find((value) => value.key === "page.landing.pros")?.value as ProsDto[]) || [], [homePage]);
	const partnerLogoDimension = window.innerWidth > 489 ? [144, 80] : [120, 60];

	const onHandleGetPartnerList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.partner.partner();

			setPartnerList(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetServices = useCallback(async () => {
		try {
			const params = queryParams.current;

			const payload = { size: 9, page: params.page };

			const [responseOurServices, responsePartnerServices] = await Promise.all([api.get.services.services("6768d0a8ec2e5b1f4e0cf209", payload), api.get.services.services("6768d0e86077cb00aa9fd2d9", payload)]);

			setOurServices(responseOurServices.data.data.list.content);

			setPartnerServices(responsePartnerServices.data.data.list.content);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetClientData = useCallback(async () => {
		let response = null;
		const payload = { size: 10, page: 0 };

		try {
			response = await api.get.clients.clients(payload);

			const clientsList = response.data.data.list.content;

			setClientsData(clientsList);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;
			serveRequestErrors(err);
		}
	}, []);

	const getReorderedFaqData = useCallback((data: any[]) => {
		if (window.innerWidth <= 991) {
			setReorderedFaqData(data.slice(0, 10));
		} else {
			const limitedData = data.slice(0, 10);
			const reordered = new Array(limitedData.length);

			for (let i = 0; i < 5 && i < limitedData.length; i++) {
				reordered[i * 2] = limitedData[i]; // Left column: 0, 1, 2, 3, 4

				if (i + 5 < limitedData.length) {
					reordered[i * 2 + 1] = limitedData[i + 5]; // Right column: 5, 6, 7, 8, 9
				}
			}
			setReorderedFaqData(reordered);
		}
	}, []);

	const onHandleGetFaq = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.faq.faq();
			setFaqData(response.data.data.list);
			getReorderedFaqData(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, [getReorderedFaqData]);

	const onHandleOurArticles = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 3, page: params.page, param: params.search };

			response = await api.get.ourArticles.latestArticles(payload);

			setArticleData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const onHandleGetAnnouncement = useCallback(async () => {
		try {
			const response = await api.get.announcement.display();
			const ads = response.data?.data?.list || [];
			setAdList(ads);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const navigateToServices = () => {
		navigate(pathnames.PageAllProducts);
	};

	useEffect(() => {
		onHandleGetClientData();
		onHandleGetFaq();
		onHandleOurArticles();
		onHandleGetPartnerList();
		onHandleGetServices();
		onHandleGetAnnouncement();
	}, [onHandleGetAnnouncement, onHandleGetClientData, onHandleGetFaq, onHandleGetPartnerList, onHandleGetServices, onHandleOurArticles]);

	useEffect(() => {
		const isFixedHeader = () => {
			const scrolledTo = window.scrollY;

			if (scrolledTo >= 120) setHeaderClassName("app-navbar__fixed--active");
			else setHeaderClassName("app-navbar__fixed");
		};

		window.addEventListener("scroll", isFixedHeader);

		return () => window.removeEventListener("scroll", isFixedHeader);
	}, []);

	useEffect(() => {
		const hasShownAds = sessionStorage.getItem(CONSTANTS.DISPLAY_ADS) === "true";
		if (adList.length > 0 && !hasShownAds && adsRef.current) {
			adsRef.current.onHandleShow();

			sessionStorage.setItem(CONSTANTS.DISPLAY_ADS, "true");
		}
	}, [adList]);

	useEffect(() => {
		if (coverPosition.length > 0 && isVideoReady) {
			setTimeout(() => {
				const el = document.querySelector(".text");
				const fx = new TextScramble(el);

				let counter = 0;
				const next = () => {
					fx.setText(coverPosition[counter]).then(() => {
						setTimeout(next, 800);
					});
					counter = (counter + 1) % coverPosition.length;
				};

				next();
			}, 1000);
		}
	}, [coverPosition, isVideoReady]);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (!videoElement) return;

		const handleCanPlay = () => {
			setIsVideoReady(true);
			videoElement.play().catch((error) => {
				console.error("Autoplay was prevented:", error);
			});
		};

		const handleError = (error: ErrorEvent) => {
			console.error("Video loading error:", error);
			setIsVideoReady(false);
		};

		videoElement.addEventListener("canplay", handleCanPlay);
		videoElement.addEventListener("error", handleError);

		// Attempt to play on load
		videoElement.load();

		return () => {
			videoElement.removeEventListener("canplay", handleCanPlay);
			videoElement.removeEventListener("error", handleError);
		};
	}, [coverImage]);

	useEffect(() => {
		const handleResize = () => getReorderedFaqData(faqData);

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [faqData, getReorderedFaqData]);

	return (
		<div className="page-home">
			<div className="home">
				<AppNavbar className={headerClassName} />

				<div className="home-container">
					<div className="home__height">
						<video ref={videoRef} src={coverImage} className="home__bg-video" autoPlay muted loop controls={false} playsInline></video>
					</div>

					<div className="home-wrapper">
						<div className="context">
							<div className="context__text">
								{isVideoReady && (
									<div className="context__text__pre__wrapper">
										{splitCoverTitle[0].split(" ", 5).map((text, index) => (
											<span key={index} className="context__text__pre">
												{text}
											</span>
										))}
									</div>
								)}
								<span className="content__container">
									<div className="text"></div>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="partners-section">
					<p className="partners-section__title" id="animation-3">
						{partnerHeader}
					</p>

					{partnerList.length > 0 && (
						<AppSlideShow>
							{partnerList.map((item, index) => (
								<div key={index}>
									<img className="marquee-logo__img" src={item.logo} alt="" width={partnerLogoDimension[0]} height={partnerLogoDimension[1]} />
								</div>
							))}
						</AppSlideShow>
					)}
				</div>

				<div className="services-section">
					<p className="services-section__title">{serviceHeader}</p>

					<div className="services-section__description">
						{serviceDescription.split("<br>").map((line, index) => (
							<p key={index}>{line}</p>
						))}
					</div>

					<p className="services-section__subtitle">{serviceSubHeader}</p>

					<div className="services-section__wrapper">
						{ourServices.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					<p className="services-section__subtitle">{serviceSubHeaderPartner}</p>

					<div className="services-section__wrapper">
						{partnerServices.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					{serviceViewLabel && (
						<div className="services-section__button-container">
							<AppButton label={serviceViewLabel} onClick={navigateToServices} />
						</div>
					)}
				</div>

				<div className="company-intro">
					<p className="company-intro__title">{prosHeader}</p>

					<div className="company-intro__wrapper">
						{prosData?.length > 0 && prosData.map((o, i) => <AppCompanyCard key={i} description={o["description 1"]} number={o.number} workforce={o.workforce} description1={o["description 2"]} />)}
					</div>
				</div>

				{clientsData.length > 0 && <AppClientSection clientsData={clientsData} />}

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">{articleHeader}</p>

						{articleViewLabel && <AppButton label={articleViewLabel} onClick={() => navigate(pathnames.PageAllArticles)} tertiary className="articles-section__btn-top" />}
					</div>
					<div className="articles-section__wrapper">
						{articleData.map((o, i) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} date={o.date} location={o.location} key={i} link={articleLink} />;
						})}
						{articleViewLabel && <AppButton label={articleViewLabel} onClick={() => navigate(pathnames.PageAllArticles)} tertiary className="articles-section__btn-bottom" />}
					</div>
				</div>

				<div className="faq-section">
					<p className="faq-section__title">{faqHeader}</p>

					{faqDescription && <p className="faq-section__description">{faqDescription}</p>}

					<div className="faq-section__container">
						{reorderedFaqData.map((o, i) => {
							const customIndex = window.innerWidth <= 991 ? i : [0, 5, 1, 6, 2, 7, 3, 8, 4, 9][i] || i;

							return <AppFaqCard question={o.question} answer={o.answer} index={customIndex} key={i} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />

				{adList.length > 0 && <AppPopupAds ref={adsRef} list={adList} />}
			</div>
		</div>
	);
};

class TextScramble {
	private el: HTMLElement | any;
	private chars: string = "!<>-_\\/[]{}—=+*^?#________";
	private queue: Array<{
		from: string;
		to: string;
		start: number;
		end: number;
		char?: string;
	}> = [];
	private frame: number = 0;
	private frameRequest?: number;
	private resolve?: () => void;

	constructor(el: any) {
		this.el = el;
		this.update = this.update.bind(this);
	}

	setText(newText: string): Promise<void> {
		const oldText = this.el.innerText;
		const length = Math.max(oldText.length, newText.length);
		const promise = new Promise<void>((resolve) => {
			this.resolve = resolve;
		});

		this.queue = [];
		for (let i = 0; i < length; i++) {
			const from = oldText[i] || "";
			const to = newText[i] || "";
			const start = Math.floor(Math.random() * 40);
			const end = start + Math.floor(Math.random() * 40);
			this.queue.push({ from, to, start, end });
		}

		cancelAnimationFrame(this.frameRequest!);
		this.frame = 0;
		this.update();
		return promise;
	}

	update() {
		let output = "";
		let complete = 0;
		for (let i = 0, n = this.queue.length; i < n; i++) {
			let { from, to, start, end, char } = this.queue[i];
			if (this.frame >= end) {
				complete++;
				output += to;
			} else if (this.frame >= start) {
				if (!char || Math.random() < 0.28) {
					char = this.randomChar();
					this.queue[i].char = char;
				}
				output += `<span class="dud">${char}</span>`;
			} else {
				output += from;
			}
		}

		this.el.innerHTML = output;
		if (complete === this.queue.length) {
			this.resolve?.();
		} else {
			this.frameRequest = requestAnimationFrame(this.update);
			this.frame++;
		}
	}

	randomChar() {
		return this.chars[Math.floor(Math.random() * this.chars.length)];
	}
}

export default PageHome;
